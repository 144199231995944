// Generated by Framer (26d4882)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["DmdV5Ymwo"];

const serializationHash = "framer-an1Ni"

const variantClassNames = {DmdV5Ymwo: "framer-v-15u9fm4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, width, ...props}) => { return {...props, RK9RqeclX: link ?? props.RK9RqeclX} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, RK9RqeclX, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "DmdV5Ymwo", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-an1Ni", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-15u9fm4", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"DmdV5Ymwo"} ref={ref ?? ref1} style={{...style}}><Link href={RK9RqeclX} openInNewTab><SVG as={"a"} className={"framer-1onxynp framer-1i7ivw4"} data-framer-name={"Youtube"} fill={"black"} intrinsicHeight={31} intrinsicWidth={31} layoutDependency={layoutDependency} layoutId={"kfprPfyN2"} svg={"<svg width=\"31\" height=\"31\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><circle cx=\"15.362\" cy=\"15.362\" r=\"15.362\" fill=\"red\"/><path d=\"M20.389 9.583H10.335a3.058 3.058 0 0 0-3.058 3.06v5.437a3.058 3.058 0 0 0 3.058 3.06H20.39a3.059 3.059 0 0 0 3.058-3.06v-5.437a3.056 3.056 0 0 0-3.058-3.06Zm-7.157 8.257v-4.956l4.26 2.478-4.26 2.478Z\" fill=\"#fff\"/></svg>"} withExternalLayout/></Link></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-an1Ni [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-an1Ni .framer-1i7ivw4 { display: block; }", ".framer-an1Ni .framer-15u9fm4 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-an1Ni .framer-1onxynp { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 31px); position: relative; text-decoration: none; width: 31px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-an1Ni .framer-15u9fm4 { gap: 0px; } .framer-an1Ni .framer-15u9fm4 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-an1Ni .framer-15u9fm4 > :first-child { margin-left: 0px; } .framer-an1Ni .framer-15u9fm4 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 31
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"RK9RqeclX":"link"}
 * @framerImmutableVariables true
 */
const FramerGKDRqkUXq: React.ComponentType<Props> = withCSS(Component, css, "framer-an1Ni") as typeof Component;
export default FramerGKDRqkUXq;

FramerGKDRqkUXq.displayName = "Social Media Icon";

FramerGKDRqkUXq.defaultProps = {height: 31, width: 31};

addPropertyControls(FramerGKDRqkUXq, {RK9RqeclX: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerGKDRqkUXq, [])